import React, { useState } from 'react';
import logo from './Logo.png';
import './App.css';
import { HubConnectionBuilder,HubConnectionState } from '@microsoft/signalr';

const App = () => {

const moleculeId = 'd8d5b9000001089f';
const [kW, setkW] = useState('0');
const [buttonState, setButtonState] = useState('Start');

const getInstaPower = async (moleculeId: any) => {
    const connection = new HubConnectionBuilder().withUrl("https://api.moleculesystems.com/NotificationHub").build();
    if (connection.state !== HubConnectionState.Connected) {
      console.log("signalR start");
      await connection.start();

      setButtonState('Running...');

      connection.invoke("GetInstantaneousPower", moleculeId);

      connection.on("gotInstantaneousPower", (message) => {
        console.log("gotInstantaneousPower: " + message);
        try {
          const json = JSON.parse(message);
          if (json.P && json.R && json.R === "A0") {
            setkW(json.P);
          }    

          if (json.S && json.S === "0") {
            connection.stop();
            setButtonState('Start');
            setkW("0");
            console.log("connection closed.");
          }
          
        } catch (e) {
          console.log("getInstaPower: " + e);
        }
      });
    }
  };

  const startInst = () => {

    getInstaPower(moleculeId);

  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Live Stream: {kW} kW
        </p>
        <button className="btn-primary" onClick={startInst}>{buttonState}</button>
      </header>
    </div>
  );


};

export default App;
